.App {
  margin: 0px;
}

.root {
  min-width: 100%;
  min-height: 100%;
}

#background-video {
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -100;
  transform: translateX(-50%) translateY(-50%);
  background-size: cover;
  transition: 1s opacity;
}
.gscontainer {
  position: fixed;
  min-width: 100%;
  min-height: 100%;
	background-color: rgba(0, 0, 0, 0.1);
	background-image: url(images/background-image-overlay.png);
	background-size:auto auto;
  background-repeat:repeat;
}
.gswelcome {
  position: absolute;
  top: 500px;
  left: 50%;
  transform: translate(-50%, -50%);
}
.gs-version {
  text-align: center;
	font-family: sans-serif;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.65);
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}
.gs-social {
  text-align: center;
  font-size: 26px;
  color: #FFF;
}
.gs-social a {
  color: #FFF;
}
.gs-title {
  font-family: 'Ubuntu', sans-serif;
  font-size: 40px;
  font-style: italic;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}
.gs-title-stats {
  color: #FFF;
}
.gs-title-io {
  color: #FF6600;
}
.gs-title-game {
  color: #CCC;
}
